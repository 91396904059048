import logo from "./logo.svg";
import "./App.css";
import Header from "./Componants/Header";
import Whychoose from "./Componants/Whychoose";
import Whyworking from "./Componants/Whyworking";
import Footer from "./Componants/Footer";

function App() {
  return (
    <>
      <Header />
      <Whychoose />
      <Whyworking />
      <Footer />
    </>
  );
}

export default App;
