import menu from "../Assets/menus.png";
import logo from "../Assets/logo.png";
import apple from "../Assets/apple.png";
import google from "../Assets/social.png";
import cer from "../Assets/Cerified.png";
import "./Header.css";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const openExternalLink = () => {
    window.open("https://web.tarjim.pro/", "_blank");
  };

  return (
    <>
      <header>
        <nav>
          <img src={logo} className="header_logo" />
          <ul className="header_menus">
            <li>About</li>
            <li>Contact</li>
            <button className="header_hostbtn" onClick={openExternalLink}>
              Became a Partner
            </button>
            <button className="header_lngbtn">
              <div></div>
              En <img src={menu} />
            </button>
          </ul>
        </nav>
        <div className="header_main">
          <div className="header_main_left">
            <h1 className="header_left_title">
              Certified Document <br />
              Translation Online
            </h1>
            <p className="header_left_desc">Anytime, Anywhere!</p>
            <p className="header_left_desc">
              Get Your Documents Translated by Experts.
            </p>
            <p className="header_startbtn">START YOUR TRANSLATION NOW!</p>
            <div className="header_downloadbtns">
              <button className="header_download">
                <img src={google} className="header_download_img" />
                Google Play
              </button>
              <button className="header_download">
                <img src={apple} className="header_download_img2" />
                App Store
              </button>
            </div>
          </div>
          <div className="header_main_right">
            <img src={cer} />
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
